import React from "react"
import { Link } from "gatsby"

const Price = () =>  {
    return(
        <div id="price" className="price component">
            <section>
                <h1 className="section-title">料金</h1>
                <p className="headline">入会金はいただいておりません</p>
                <table>
                    <tr>
                        <th className="course1">ひよこコース</th>
                        <td>7,000円/1ヶ月</td>
                        <td>年間36回</td>
                        <td>1レッスン30分</td>
                    </tr>
                    <tr>
                        <th rowspan="2" className="course2">ジュニアコース</th>
                        <td>10,500円/1ヶ月</td>
                        <td>年間36回</td>
                        <td>1レッスン45分</td>
                    </tr>
                    <tr>
                        <td className="dot">14,000円/1ヶ月</td>
                        <td className="dot">年間36回</td>
                        <td className="dot">1レッスン60分</td>
                    </tr>
                    <tr>
                        <th rowspan="2" className="course4">大人のピアノコース</th>
                        <td>3,300円/1回</td>
                        <td></td>
                        <td>1回30分</td>
                    </tr>
                    <tr>
                        <td className="dot">5,500円/1回</td>
                        <td className="dot"></td>
                        <td className="dot">1回60分</td>
                    </tr>
                    <tr>
                        <th className="course5">ソルフェージュコース</th>
                        <td>4,400円〜/1回</td>
                        <td></td>
                        <td>1回40分〜</td>
                    </tr>
                </table>
                <div className="price-text">
                    <p className="price-para">◎全コースともレッスン時間は、教室出入り時間を含みます</p>
                    <p className="price-para">◎当教室では設備維持費はいただいておりません。</p>
                    <p className="price-para"><span>◎ひよこコース</span>ジュニアコースのレッスン料は年間回数を月割りにしたものです。1ヶ月に5回、2回のレッスン月になる場合も1ヶ月のレッスン料金は変わりません。</p>
                    <p className="price-para">振替レッスンは下記の場合のみ実施します。</p>
                    <p className="price-para">・講師の都合による休講</p>
                    <p className="price-para">・運動会などの学校行事</p>
                    <p className="price-para">・その他、講師が必要とする場合</p><p className="price-para">（生徒さんのご病気などで欠席が多い場合、講師と生徒さん
                    の予定が合えば出来るだけ対応させていただきます）</p>
                    <p className="price-para">◎月の途中退会による未消化分のレッスン料は返金できかねますので予めご了承ください。</p>
                </div>
                <Link to="#footer"><div className="contact"><span>申込み/お問合せ</span></div></Link>
            </section>
        </div>
    )
}
export default Price