import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Top from "../components/top"
import Fb from "../components/fb"
import Column from "../components/column"
import Course from "../components/course"
import Price from "../components/price"
import Profile from "../components/profile"
import Access from "../components/access"
import Contact from "../components/contact"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Top />
    <Fb />
    <Column />
    <Course />
    <Price />
    <Profile />
    <Access />
    <Contact />
  </Layout>
)

export default IndexPage
