import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Footer = () =>  {
    return(
        <div id="footer" className="footer">
            <div className="free-lesson">
                <h2>体験レッスン受付中</h2>
                <h6>（レッスン見学等はご相談下さい。）</h6>
                <p>1回  約30分  2,000円<br />ただし入会された場合は無料となります。</p>
                <ul>
                    <li><StaticImage src="../images/mail.png" placeholder="tracedSVG" alt="icon" className="icon" />march.piano0001@gmail.com</li>
                    <li><StaticImage src="../images/phone.png" placeholder="tracedSVG" alt="icon" className="icon" />03-3910-3738</li>
                    <li><Link to="https://www.facebook.com/march.piano0001" placeholder="tracedSVG" target="_blank" rel="noopener noreferrer"><StaticImage src="../images/fb.png" alt="icon" className="icon" />公式facebook</Link></li>
                </ul>
            </div>
        </div>
    )
}
export default Footer