import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Profile = () =>  {
    return(
        <div id="profile" className="profile component">
            <section>
                <div className="profile-section">
                    <h1 className="section-title">講師プロフィール</h1>
                    <p className="headline">＊担当コース以外のご希望があれば<br />ご相談ください</p>
                    <div className="clearfix">
                        <div className="profile-container">
                            <StaticImage src="../images/藤森妙子-2023.jpg" alt="profile-img" className="profile-img" />
                            <div className="profile-text">
                                <h6>藤森妙子</h6>
                                <p>現ヤマハ音楽教室システム講師<br />ヤマハピアノグレード10〜6級試験官<br />国立音楽大学ピアノ科卒業<br />担当コース：ひよこ、ジュニア、大人ピアノ</p>
                            </div>
                        </div>
                        <div className="profile-container">
                            <StaticImage src="../images/sanachan.jpg" alt="profile-img" className="profile-img" />
                            <div className="profile-text">
                                <h6>藤森さな</h6>
                                <p><span>作.編曲家</span>伴奏ピアニスト<br />東京藝術大学作曲科卒業<br />担当コース：ソルフェージュ、大人ピアノ</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="bg-wall"></div>
            <div className="bg-wall2"></div>
            <div className="bg-wall3"></div>
        </div>
    )
}
export default Profile