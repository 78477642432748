import React from "react"

const Access = () =>  {
    return(
        <div id="access" className="component access">
            <section className="access-section">
                <h1 className="section-title">アクセス</h1>
                <div className="clearfix access-inner">
                    <div className="item">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3238.2643025471234!2d139.74763271526047!3d35.7443079801797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d8d9d4ee8e5%3A0x88906e92de6028a!2s1+Chome-11-5+Kaminakazato%2C+Kita-ku%2C+T%C5%8Dky%C5%8D-to+114-0016!5e0!3m2!1sen!2sjp!4v1485845682036" frameborder="0" allowfullscreen className="map" title="map"></iframe>
                    </div>
                    <div className="item map-caption float-right">
                        <h4>上中里教室</h4>
                        <h5>東京都北区上中里1-11-5</h5>
                        <p>JR京浜東北線 上中里駅 徒歩4分</p>
                        <p>東京メトロ南北線 西ヶ原駅  徒歩10分</p>
                        <p>JR山手線 駒込駅 徒歩12分</p>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Access