import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ siteTitle }) => {
  return(
    <header>
      <div className="top component">
        <div className="top-image">
          <StaticImage src="../images/kaminaka4.jpg" quality="100" placeholder="blurred" alt="top" />
        </div>
        <StaticImage src="../images/top-title.png" placeholder="tracedSVG" alt="top-title" className="top-title" />
        <div className="mv__scroll u-d-none u-d-md-block"><div className="mv__scroll-button"><div className="mv__scroll-inner">SCROLL DOWN</div></div></div>
        <button id="myBtn" className="go-top">
            <Link to="#">
                <StaticImage src="../images/gotop.svg" placeholder="tracedSVG" alt="gotop" />
            </Link>
        </button>
        <p className="copyright_rotate">© March  Piano School All Rights Reserved</p>
        <p><a href="https://www.facebook.com/march.piano0001" className="social_rotate" target="_blank" rel="noopener noreferrer">- facebook -</a></p>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
