import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Course = () =>  {
    return(
        <div id="course" className="course component">
            <h1 className="section-title">コース紹介</h1>
            <p className="headline">防音完備のレッスン室ですのでお仕事で忙しい方も夜10時までのレッスンが可能です</p>
            <section className="course-container">
                <div className="course-panel">
                        <h2 className="course-name">ひよこコース</h2>
                        <StaticImage src="../images/course_1.jpg" alt="course-img" className="course-img" />
                        <p className="target">対象 …… 未就学児</p>
                        <p>ソルフェージュを中心にピアノ指導へ導入します。<br />この時期のお子様にはご家族の応援が不可欠です。レッスンはできる限り保護者の方同伴をお願いします。</p>
                </div>
                <div className="course-panel">
                    <h2 className="course-name">ジュニアコース</h2>
                    <StaticImage src="../images/course_2.jpg" alt="course-img" className="course-img" />
                    <p className="target">対象 …… 小学生〜高校生</p>
                    <p>初級ソルフェージュとピアノの併用レッスン。コンクール、ヤマハグレード等も指導。</p>
                </div>
                <div className="course-panel">
                    <h2 className="course-name">大人のピアノコース</h2>
                    <StaticImage src="../images/course_4.jpg" alt="course-img" className="course-img" />
                    <p className="target">対象 …… 高校生以上</p>
                    <p>平日の午前中や、空いた時間でお好きな曲を弾けるようにレッスンします。毎週では少し忙しいとお感じの方へ1レッスン制としました。</p>
                </div>
                <div className="course-panel">
                    <h2 className="course-name">ソルフェージュコース</h2>
                    <StaticImage src="../images/course_5.jpg" alt="course-img" className="course-img" />
                    <p className="target">対象 …… 小学生〜大人</p>
                    <p>楽典・新曲・視唱・聴音アナリーゼ・作曲</p>
                </div>
            </section>

            <Link to="#footer"><div className="contact"><span>申込み/お問合せ</span></div></Link>
        </div>
    )
}
export default Course