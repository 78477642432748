import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Column = () =>  {
    return(
        <div className="column component">
            <div className="column-left">
                <StaticImage src="../images/kaminaka1.jpg" alt="kaminaka1" />
            </div>
            <div className="column-para">
                <p className="column-text" id="column-text">東京都北区で開講しているピアノ教室です<br /><br />始めたい時がベストタイミング！<br />さあ、貴方もレッスンを始めましょう！<br /><br />マーチぴあの教室では、幼児から大人の方までお一人お一人のご希望に沿って楽しくレッスンしております。（男性の方はご紹介のある方とさせていただいております）<br /><br />音楽大学・高校受験、ヤマハグレード、コンクールなど対応します。歌うことで音楽を感じ、メロディーに和音を付けたり自分だけのアレンジで演奏して楽しむために、マーチぴあの教室では演奏技術プラスαを大切に考えます。そのために必要なソルフェージュ指導を毎回のレッスンに取り入れたジュニアコースでは幅広い音楽力を身につけることができます。</p>
            </div>
            <div className="column-right">
                <StaticImage src="../images/kaminaka3.jpg" alt="kaminaka3" />
            </div>
        </div>
    )
}
export default Column