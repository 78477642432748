import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Fb = () =>  {
    return(
        <div className="fb component">
            <StaticImage src="../images/fb-back.jpg" quality="100" placeholder="blurred" alt="top" className="fb-bg" />
            <div className="fb-inner">
                <p className="news">NEWS</p>
                <p className="news-line"><a href="https://www.facebook.com/march.piano0001" className="fb_news_link" target="_blank" rel="noopener noreferrer">新着ニュースはこちらのFacebookページも合わせてご覧ください。</a></p>
                <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fmarch.piano0001&tabs=timeline&width=950&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" style={{width: "60%", height: "400px", border: "none", overflow: "hidden", display: "block" }} scrolling="no" frameborder="0" allowTransparency="true" className="facebook-iframe" title="facebook-iframe"></iframe>
            </div>
        </div>
    )
}
export default Fb